import { auth } from "config/config"
import { signIn as naSignIn } from "next-auth/react"
import { useEffect } from "react"
import { v4 as generateId } from "uuid"

import { getSessionId } from "../lib/http/http"

const allowIpLoginQuery = "allowIpLogin"

/**
 * If allowIpLogin=false is set, adds flag to session storage on load
 * @deprecated not in use (we think)
 */
export function useAllowIpLogin() {
    useEffect(() => {
        const params = new URLSearchParams(window?.location?.search)
        if (params.has(allowIpLoginQuery)) {
            const shouldDisableIP = params.get(allowIpLoginQuery)
            if (shouldDisableIP === "false") {
                window.sessionStorage.setItem(allowIpLoginQuery, "false")
            }
        }
    }, [])
}

/**
 * Looks for ?allowIPLogin=false in url.
 * Used for proxies that want to disable IP login on demand
 * Default to true
 * @deprecated not in use (we think)
 * @returns string value of boolean value
 */
function shouldAllowIp() {
    if (typeof window === "undefined") {
        return "true"
    }
    if (window.sessionStorage.getItem(allowIpLoginQuery) === "false") {
        return "false"
    }

    return "true"
}

/**
 * Custom `next-auth` signIn method that adds
 * `X-Session-ID` an `X-Correlation-ID` to the body of the request.
 */

interface ISignInProps {
    callbackUrl?: string
    authorization?: Record<string, string>
}

/**
 * Custom `next-auth` signIn method with refresh session
 * @param callbackUrl the url to return to after sign in, defaults to current page
 * @param authorization OIDC authorization parameters
 * @returns something
 */
export function signIn({ callbackUrl, authorization }: ISignInProps = {}) {
    return naSignIn(
        auth.provider,
        {
            callbackUrl,
            "X-Session-ID": getSessionId(),
            "X-Correlation-ID": generateId()
        },
        {
            allowIpLogin: shouldAllowIp(),
            ...authorization
        }
    )
}
