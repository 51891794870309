import configJSON from "config/config.json"

interface IConfig {
    general: {
        appHost: string
        enableNotifications: boolean
        enableDictionary: boolean
        enableRegionalContent: boolean
        enableConfirmUserDetails: boolean
        enableInternalTracking: boolean
        safeExternalURLs: string
        orderPagesURL: string
        cookieBotDomainGroupId: string
        recaptchaId: string
        searchManagerConfig: string
        googleSiteVerification: string
        feedback: {
            defaultValue: string
        }
        suppressNewAndRevisedBadges: boolean
    }
    auth: {
        provider: string
        clientId: string
        hasIPAccess: boolean
        defaultMaxConcurrentLogins: number
        roles: {
            admin: string[]
            editor: string[]
            subscriber: string[]
        }
    }
    staticPaths: { [key: string]: string }
    rewrites: { [key: string]: string }
    externalDialog?: { [key: string]: string }
    licenseRequest?: { email: string }
}

export const {
    general,
    auth,
    staticPaths,
    rewrites,
    externalDialog,
    licenseRequest
}: IConfig = configJSON[process.env.NEXT_PUBLIC_ID]
